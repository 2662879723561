.mod-stats {
  text-align: left;
  width: 13em;
  position: relative;
}

.mod-stats h4 {
  text-transform: uppercase;
  margin: 0;
}

.mod-stats ul {
  list-style: none;
  padding-left: 1em;
  margin: 0 0 .5em 0;
  font-weight: lighter;
}

.mod-stats ul.secondary {
  color: #c5f5f5;
}

.mod-stats ul li {
  line-height: 1.2em;
  margin: 0;
}

.mod-stats .class {
  display: inline-block;
  float: right;
  height: 1em;
}

.mod-stats .class-S {
  color: #eeca44;
}

.mod-stats .class-A {
  color: #a35ef9;
}

.mod-stats .class-B {
  color: #2997f1;
}

.mod-stats .class-C {
  color: #8fff3a;
}

.mod-stats .class-D {
  color: #ccfffe;
}

.mod-stats .avatar {
  display: inline-block;
  vertical-align: middle;
  font-size: .8em;
}

.mod-stats .avatar-name {
  vertical-align: middle;
  margin-left: .5em;
}

.mod-stats button.delete-button {
  position: absolute;
  top: 0;
  right: 0;
}
