.mod-detail,
.delete-mod-display {
  position: relative;
  display: inline-grid;
  grid-template-columns: 5em 1fr;
  grid-template-rows: 1fr auto auto;
  grid-column-gap: 1em;
  grid-row-gap: 0;
  align-items: start;
  border: 1px solid dodgerblue;
  background-color: rgba(0, 0, 96, .5);
  color: white;
  text-shadow: 2px 2px 1px black;
  margin: 1em;
  padding: 1em;
  width: 18em;
}

.mod-detail .mod-image,
.delete-mod-display .mod-image {
  vertical-align: top;
  grid-row: 1;
  grid-column: 1;
  width: 4em;
}

.mod-detail > .avatar,
.delete-mod-display > .avatar {
  grid-row: 2;
  grid-column: 1;
  text-align: center;
  font-size: .8em;
  margin-top: 1.75em;
}

.mod-detail .character-name,
.delete-mod-display .character-name {
  grid-row: 3;
  grid-column: 1 / span 2;
  margin: 0;
  text-align: left;
  font-weight: normal;
  font-size: .8em;
}

.mod-detail > .stats,
.delete-mod-display > .stats {
  grid-row: 1 / span 2;
  grid-column: 2 / span 2;
  font-size: .9em;
}

.mod-detail > .stats > .assigned {
  float: right;
  font-size: .6em;
}

.mod-detail > .stats > .assigned .arrow {
  width: 4em;
  height: 4em;
}

.mod-detail button.delete-button {
  position: absolute;
  top: 0;
  right: 0;
}
